<template>
    <nav>
        <v-app-bar app color="#FF5733" dark elevate-on-scroll dense>
            <div class="d-flex align-center">

                <v-btn v-if="!barra" @click="drawer = !drawer" fab x-small>
                    <v-app-bar-nav-icon x-small color="white">
                    </v-app-bar-nav-icon>
                </v-btn>

            </div>

            <v-spacer></v-spacer>
            <v-card v-if="$store.state.permisos != ''" elevation="12" class="mr-6 mt-3" color="white"
                @click.prevent="abre_whats()">
                <v-container>
                    <v-img class="mx-auto mt-n2 mb-n2" height="40" width="40" src="/what.png"></v-img>
                </v-container>
                <v-container class="mt-n12 ml-1">
                    <v-badge :content="mensajes_ws" :value="mensajes_ws" color="red" overlap class="ml-10">
                    </v-badge>
                </v-container>
            </v-card>
            <v-card v-if='false' elevation="12" class="mr-6 mt-3" color="white" @click.prevent="sincroniza()">
                <v-container>
                    <v-img class="mx-auto mt-n2 mb-n2" height="40" width="40" src="/regresh.png"></v-img>
                </v-container>
            </v-card>
            <v-card v-if="$store.state.activa_pagos" elevation="12" class="mt-3" color="white"
                @click.prevent="$store.commit('abre_qr', true)">
                <v-container>
                    <v-img class="mx-auto mt-n2 mb-n2" height="40" width="40" src="/lector_qr.png"></v-img>
                </v-container>
            </v-card>
            <v-card v-if="$store.state.activa_pagos" elevation="12" class="ml-6 mt-3" color="white"
                @click="dialog_modo_pago = true">
                <v-container>
                    <v-img class="mx-auto mt-n2 mb-n2" height="40" width="40" src="/pagos.png"></v-img>
                </v-container>
            </v-card>

            <v-card elevation="12" class="ml-6 mt-3" color="white" @click="refresca(), dialog_pacientes = true">
                <v-container>
                    <v-img class="mx-auto mt-n2 mb-n2" height="40" width="40" src="/doctor.png">
                    </v-img>
                </v-container>
                <v-container class="mt-n12 ml-1">
                    <v-badge :content="mensajes" :value="mensajes" color="green" overlap class="ml-10">
                    </v-badge>
                </v-container>
            </v-card>

        </v-app-bar>

        <v-navigation-drawer v-if="!barra" v-model="drawer" temporary app>

            <v-list-item class="px-3">
                <v-list-item-avatar>
                    <v-img
                        src="https://firebasestorage.googleapis.com/v0/b/mitienda-f5ef8.appspot.com/o/carpetaiconos%2Ficono25?alt=media&token=9ead45f5-7735-4386-8647-e1d80cc913ab"></v-img>
                </v-list-item-avatar>

                <v-list-item-title class="mb-2">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <span style="font-size: 0.8em; color: #FF2D00;">Usuario:</span>

                        </v-col>
                        <v-col cols="12">
                            <span style="font-size: 0.8em ; text-transform:uppercase">{{ nombreusuario }}</span>
                        </v-col>
                    </v-row>
                    <v-btn dark v-if="$store.state.permisos.master" class="ma-2" color="red" small text
                        @click="abre_tiendas()">
                        {{ $store.state.permisos.tienda }}
                        <v-icon color="white" right>
                            mdi-storefront
                        </v-icon>

                    </v-btn>

                </v-list-item-title>

                <v-btn icon @click.stop="drawer = !drawer">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item link @click.prevent="router('panel')">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-group v-if="$store.state.permisos.modulocaja && $store.state.baseDatos.caja2" :value="listaMenu"
                    prepend-icon="mdi-cash-register">
                    <template v-slot:activator>
                        <v-list-item-title>Ventas</v-list-item-title>
                    </template>
                    <v-container>

                        <v-list-item v-if="$store.state.permisos.modulocaja && $store.state.baseDatos.caja2" link
                            @click.prevent="router('ventas')">
                            <v-list-item-icon>
                                <v-icon>mdi-cash-register</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Nueva Venta</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link @click.prevent="router('proformas')">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Proformas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('rep_garantias')">
                            <v-list-item-icon>
                                <v-icon>mdi-star-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Grantias</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('reporte_parametros')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-search</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Parametros</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('pendientes')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-clock-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Pendientes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('flujo_caja')">
                            <v-list-item-icon>
                                <v-icon>mdi-chart-areaspline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Flujo de Caja</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-container>
                </v-list-group>
                <v-list-group v-if="$store.state.permisos.modulocaja" :value="listaMenu" prepend-icon="mdi-dump-truck">
                    <template v-slot:activator>
                        <v-list-item-title>Almacen</v-list-item-title>
                    </template>
                    <v-container>
                        <v-list-item link @click.prevent="router('monturas')">
                            <v-list-item-icon>
                                <v-icon>mdi-glasses</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Monturas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('accesorios')">
                            <v-list-item-icon>
                                <v-icon>mdi-screw-lag</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Accesorios</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('lista_precios')">
                            <v-list-item-icon>
                                <v-icon>mdi-package-variant-closed</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Lista Precios</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('productos')" v-if="$store.state.permisos.pedidos">
                            <v-list-item-icon>
                                <v-icon>mdi-package-variant-closed</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Productos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('lentes_contacto')"
                            v-if="$store.state.permisos.pedidos">
                            <v-list-item-icon>
                                <v-icon>mdi-emoticon-cool-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Lentes Contacto</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>
                <v-list-group v-if="$store.state.permisos.moduloclientes" :value="listaMenu"
                    prepend-icon="mdi-account-multiple">
                    <template v-slot:activator>
                        <v-list-item-title>Clientes</v-list-item-title>
                    </template>
                    <v-container>
                        <v-list-item link @click.prevent="router('lista_clientes')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-search</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Lista Clientes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('historico_ventas')">
                            <v-list-item-icon>
                                <v-icon>mdi-chart-bell-curve</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Historial Clientes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>
                <v-list-group v-if="$store.state.permisos.optometria" :value="listaMenu"
                    prepend-icon="mdi-eye-circle-outline">
                    <template v-slot:activator>
                        <v-list-item-title>Optometria</v-list-item-title>
                    </template>
                    <v-container>
                        <v-list-item link @click.prevent="router('optometria')">
                            <v-list-item-icon>
                                <v-icon>mdi-glasses</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Mediciones</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                        <v-list-item link @click.prevent="router('reporte_recetas')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-injury-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Historial Pacien.</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('reporte_parametros')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-search</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Parametros</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>
                <v-list-group v-if="$store.state.permisos.pedidos" :value="listaMenu" prepend-icon="mdi-dolly">
                    <template v-slot:activator>
                        <v-list-item-title>Pedidos</v-list-item-title>
                    </template>
                    <v-container>
                        <v-list-item link @click.prevent="router('lista_pedidos')">
                            <v-list-item-icon>
                                <v-icon>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Lista Pedidos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('garantias')">
                            <v-list-item-icon>
                                <v-icon>mdi-star-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Garantias</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('bicelados')">
                            <v-list-item-icon>
                                <v-icon>mdi-hammer-wrench</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Rep.Bicelados</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>
                <v-list-group v-if="$store.state.permisos.modulocaja" :value="listaMenu" prepend-icon="mdi-dump-truck">
                    <template v-slot:activator>
                        <v-list-item-title>Kardex</v-list-item-title>
                    </template>
                    <v-container>
                        <v-list-item link @click.prevent="router('movimientos_kardex')"
                            v-if="$store.state.permisos.master">
                            <v-list-item-icon>
                                <v-icon>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Kardex</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('almacen_accesorios')"
                            v-if="$store.state.permisos.master">
                            <v-list-item-icon>
                                <v-icon>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Almacen Accesorios</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('almacen_monturas')"
                            v-if="$store.state.permisos.master">
                            <v-list-item-icon>
                                <v-icon>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Almacen Monturas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('traslado_monturas')">
                            <v-list-item-icon>
                                <v-icon>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Traslado Monturas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click.prevent="router('recepcion_monturas')">
                            <v-list-item-icon>
                                <v-icon>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Recepcion Monturas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>

                <v-list-group v-if="$store.state.permisos.modulosunat" :value="listaMenu"
                    prepend-icon="mdi-text-box-multiple-outline">
                    <template v-slot:activator>
                        <v-list-item-title>SUNAT</v-list-item-title>
                    </template>
                    <v-container>

                        <v-list-item @click.prevent="router('boletaFactura')">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Boletas, Facturas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click.prevent="router('creditoDebito')">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>N.Credito, N.Debito</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click.prevent="router('resumenbaja')">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Resumen Bajas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click.prevent="router('ticket')">
                            <v-list-item-icon>
                                <v-icon>mdi-web-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Tickets</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>

                <v-list-group v-if="$store.state.permisos.moduloreportes" :value="listaMenu"
                    prepend-icon="mdi-chart-bar">
                    <template v-slot:activator>
                        <v-list-item-title>REPORTES</v-list-item-title>
                    </template>
                    <v-container>
                        <v-list-item @click.prevent="router('venta_general')">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Venta General</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click.prevent="router('comprobantes')">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Comprobantes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click.prevent="router('comisiones')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-cash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Comisiones</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click.prevent="router('reg_contables')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-cash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Rep.Contables</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click.prevent="router('reportes')" v-if="false">
                            <v-list-item-icon>
                                <v-icon>mdi-finance</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Ranking Semanal</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>

                <v-spacer></v-spacer>
                <v-divider></v-divider>

                <v-list-item v-if="user == 'ivanac1992@domotica.com'" @click.prevent="router('panelgeneral')">
                    <v-list-item-icon>
                        <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Admin</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="$store.state.permisos.moduloempresa" link @click.prevent="router('miempresa')">
                    <v-list-item-icon>
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Configuracion</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click.prevent="accion('Cerrar Sesion')">
                    <v-list-item-icon>
                        <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Cerrar Sesion</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>
        <v-dialog v-model="dialog_tiendas" scrollable max-width="500px">
            <v-card>
                <h4 class="text-center">SELECCIONE TIENDA</h4>
                <v-simple-table class="elevation-1 mt-2" fixed-header height="70vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Tienda
                                </th>
                                <th class="text-left">
                                    B.Datos
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for=" item in $store.state.array_tiendas " :key="item.id"
                                @click="select_tienda(item)">
                                <td>{{ item.nom_tienda }}</td>
                                <td>{{ item.bd }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_pacientes" scrollable max-width="800px">
            <v-card class="pa-1">
                <v-simple-table class="elevation-1 mt-2" fixed-header height="70vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Paciente
                                </th>
                                <th class="text-left">
                                    Diagnostico
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for=" item in listafiltrada " :key="item.id" @click="selec_paciente(item)">
                                <td>{{ item.nom_cliente }}</td>
                                <td>{{ item.diagnostico }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_modo_pago" scrollable max-width="400px" transition="dialog-bottom-transition">
            <v-card class="pa-2">
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="abre_modo('yape')">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/yape.png"></v-img>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6"
                        v-if="$store.state.permisos.tienda != 'MERCADERES' && $store.state.permisos.tienda != 'VIRREY'">
                        <v-card @click.prevent="abre_modo('plin')">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/plin.webp"></v-img>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="abre_modo('transferencia')">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/bank.png"></v-img>
                            </v-container>
                        </v-card>
                    </v-col>

                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog ref="dialog" v-model="dial_bloqueo" persistent max-width="500px">
            <div>
                <v-system-bar window dark>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-4">
                <v-img class="mx-auto" height="80" width="80" src="/iconalerta.png"></v-img>
                <v-row class="mx-auto mt-1">
                    <v-col>
                        <v-card class="pa-1" elevation="8">
                            <v-card-text class="text-center">
                                <h3> EL SISTEMA ESTA BLOQUEADO TEMPORALMENTE!! COMUNIQUESE CON SU ADMINISTRADOR</h3>
                            </v-card-text>
                            <v-card-actions v-if=false>
                                <v-row class="mx-auto">
                                    <v-col>
                                        <v-btn @click="" block color="info">
                                            LISTO
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <dialog_modos v-if="dial_modos_" :data="modo_pago" @cierra="dial_modos_ = false" />
        <mensaje />
        <dialogbloqueo />
        <dialogoprogress />
        <dialogosnackbar />
        <v-dialog v-model="dial_sms" max-width="1150px">

            <iframe v-if="dial_sms" :src="externalPageUrl" width="100%" height="650vh" @load="onIframeLoad"></iframe>

        </v-dialog>

        <sms_vision v-if="dial_sms2" @cierra="dial_sms2 = false" />

        <v-dialog v-model="loading" persistent width="300">
            <v-card>
                <v-card-text>
                    <v-progress-linear indeterminate color="blue"></v-progress-linear>
                    <div class="text-center mt-3">Cargando...</div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </nav>
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'
import * as faceapi from 'face-api.js';
import {
    obtenerBD,
    all_mediciones,
    busca_tabla,
    allEmpresas,
    consultaArchivo,
    allUsuarios,
    allnotificaciones,
    allProductos,
    all_lentes_c,
    allClientes,
    obtenerImpresoras,
    allConfigura,
    nuevoCampoUsuario,
    obtenerSeries,
    allMonturas,
    all_tienda,
    buscarUsuarios_bloqueo,
    leer_local,
    elimina_medicion,
    guardar_local,
    leer_local_montura,
    guardar_ip,
    busca_ip,
    allEmpleados
} from '../db'
import {
    lee_lista_rapida,
} from "../bd_ws.js";
import store from '@/store/index'
import mensaje from '@/components/dialogos/dialogMensaje'
import dialogoprogress from '@/components/dialogos/dialogoprogress'
import dialogbloqueo from '@/components/dialogos/dialogBloqueo'
import dialog_modos from '@/components/dialogos/dialog_modos_pagos'
import sms_vision from '@/views/mensajeria/sms_vision'
import dialogosnackbar from '@/components/dialogos/dialogosnackbar'
import imageToBase64 from 'image-to-base64/browser'
import isMobile from 'mobile-device-detect'
import moment from 'moment'

export default {
    name: 'barrasuperior',

    components: {
        mensaje,
        dialogoprogress,
        dialogosnackbar,
        dialog_modos,
        dialogbloqueo,
        sms_vision
    },
    data() {
        return {
            dial_sms2: false,
            dial_bloqueo: false,
            modo_pago: [],
            dial_modos_: false,
            dialog_tiendas: false,
            dialog_modo_pago: false,
            listaMenu: false,
            drawer: false,
            user: '',
            nombreusuario: '',
            barra: true,
            bd: '',
            dialog: false,
            messages: 0,
            selected: [],
            items: [{
                action: '15 min',
                headline: 'Tenemos novedades para Ti',
                subtitle: 'Facturacion Domotica System esta habilitado para ti, los 365 dias',
                title: 'BIENVENIDO!',
            },],
            tienda_selecta: '',
            dialog_pacientes: false,
            desserts: [],
            dial_sms: false,
            loading: false,
            externalPageUrl: '',
            sms_ws: []
        }
    },
    created() {
        this.loadModels()
        this.inicio()
        this.analiza_ip()

    },

    beforeDestroy() {
        all_mediciones().off();
        allClientes().off()
        allProductos().off()
        all_lentes_c().off()
        lee_lista_rapida().off()
        allMonturas().off()
    },
    computed: {
        listafiltrada() {
            return this.desserts
        },
        mensajes() {
            this.desserts
            this.messages = this.listafiltrada.length
            return this.messages
        },
        mensajes_ws() {
            var array = this.sms_ws
            if (store.state.permisos.master) {
                return this.sms_ws.length
            } else {
                var aa = this.sms_ws.filter(e => e.tienda == store.state.permisos.tienda)
                return aa.length
            }
        }
    },
    methods: {
        abre_whats() {
            /*//this.externalPageUrl = 'http://localhost:8080/sms_vision/BD4/' + store.state.permisos.tienda
            this.externalPageUrl = 'https://opticenter-sms.web.app/sms_vision/BD4/' + store.state.permisos.tienda
            this.dial_sms = true
            this.loading = true*/
            this.dial_sms2 = !this.dial_sms2
        },
        async onIframeLoad() {
            this.loading = false;
        },
        async loadModels() {
            await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
            await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
            await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
            this.modelsLoaded = true; // Indicar que los modelos se han cargado
        },
        create_UUID() {
            //this.create_UUID().substring(29)
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        async analiza_ip() {
            var ult_consulta = await leer_local('actualizacion')
            if (ult_consulta[0] == undefined) {
                var uuid = this.create_UUID().substring(29)
                guardar_local('actualizacion', {
                    id: '01',
                    tabla_clientes: uuid
                })
                guardar_ip(uuid)
            } else {

                var snap = await busca_ip(ult_consulta[0].tabla_clientes).once('value')
                if (snap.exists()) {
                    console.log(snap.val())
                    if (snap.val().estado == 'aceptado') {

                    } else {
                        this.dial_bloqueo = false
                    }
                } else {
                    var uuid = this.create_UUID().substring(29)
                    guardar_local('actualizacion', {
                        id: '01',
                        tabla_clientes: uuid
                    })
                    await guardar_ip(uuid)
                    await new Promise(resolve => setTimeout(resolve, 1000))
                    this.analiza_ip()
                }
            }
            /* var snap = await busca_ip(ip.split(".").join('')).once('value')
             if (snap.exists()) {
                 console.log(snap.val())
                 if (snap.val().estado == 'aceptado') {

                 } else {
                     this.dial_bloqueo = true
                 }
             } else {

                 await new Promise(resolve => setTimeout(resolve, 1000))
                 this.analiza_ip(ip)
             }*/

        },
        inicio() {

            lee_lista_rapida('BD4').orderByChild("fecha").limitToLast(100).on('value', (snapshot) => {
                this.sms_ws = []
                let array = [];
                snapshot.forEach((item) => {
                    let data = item.val();

                    var cli = store.state.clientes.find((e) => "51" + e.telefono === data.id)
                    if (cli) {
                        data.nombre = cli.nombre
                        data.tienda = cli.tienda
                    }
                    //    console.log(store.state.permisos)
                    if (data.vista) {
                        array.push(data);
                    }
                });
                this.sms_ws = array
                console.log(array.length)
            });
            allEmpleados().on('value', (snapshot) => {
                let array = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        array.push(data);
                    });
                    store.commit('empleados', array)
                }
            })
            all_mediciones().orderByChild('estado').equalTo('PENDIENTE').on('value', (snapshot) => {
                this.desserts = []
                let array = [];
                snapshot.forEach((item) => {
                    let data = item.val();
                    if (data.estado != 'CONTROL' && data.estado != 'ATENDIDO' && this.comparacion(10080, data.fecha)) {
                        var tienda_actual = store.state.permisos.tienda
                        if (store.state.permisos.master) {
                            if (this.comparacion(180, data.fecha)) {
                                array.push(data);
                            }

                        } else {
                            if (tienda_actual != undefined) {
                                if (tienda_actual.trim() == data.tienda.trim()) {
                                    if (this.comparacion(180, data.fecha)) {
                                        array.push(data);
                                    }
                                }
                            }
                        }
                    } else {
                        elimina_medicion(data.id)
                    }
                });
                this.desserts = array.reverse();

            })
        },
        async abre_tiendas() {
            this.dialog_tiendas = true
        },
        async select_tienda(data) {
            store.commit("dialogoprogress")
            await nuevoCampoUsuario(store.state.permisos.token, 'bd', data.bd)
            await nuevoCampoUsuario(store.state.permisos.token, 'tienda', data.nom_tienda)
            location.reload(true);
            this.dialog_tiendas = false
        },

        refresca() {
            all_mediciones().off()
            this.inicio()
        },
        abre_modo(modo) {
            this.modo_pago = modo
            this.dial_modos_ = true
        },
        selec_paciente(item) {
            item.modo = 'INTERNA'
            store.commit('array_paciente', item)
            var currentUrl = window.location.pathname;
            if (currentUrl != '/ventas') {
                this.router('ventas')
            }
            this.dialog_pacientes = false
        },
        comparacion(val, fecha) {
            var hoy = moment().unix()
            var receta = moment.unix(fecha).add(val, 'm').unix()
            if (receta > hoy) {
                return true
            } else {
                return false
            }
        },
        accion(accion) {
            if (accion == "Cerrar Sesion") {
                firebase.auth().signOut().then(() => {
                    this.$router.push({
                        name: 'Home'
                    })
                    this.barra = true
                    obtenerBD(this.bd).off("value", this.onDataChange);
                    store.commit('BD', '')
                    store.commit('permisos', '')
                    console.log(store.state.permisos)
                })
            }
            if (accion == "paneladmin") {
                this.$router.push({
                    name: 'paneladmin'
                })
            }
        },
        router(view) {
            this.$router.push({
                name: view
            })
        },

        obtenEmpresa() {
            allEmpresas().once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    // console.log(item.val())
                    if (item.val().usuario == this.user) {
                        this.bd = item.val().bd
                        console.log(this.bd)
                        this.obtenerCliente()
                    }
                })
            });
        },
        obtenerCliente() {
            this.tienda_selecta = store.state.permisos.tienda
            obtenerBD(this.bd).once("value").then((snapshot) => {
                store.commit('BD', snapshot.val())
                store.commit('esmovil', isMobile.isAndroid)
                if (snapshot.exists) {
                    this.obtenconfiguracion()
                    this.notificaciones(snapshot.val())
                    this.carga_empleados()
                    this.obtenproductos()
                }
            });
        },
        obtenconfiguracion() {
            allConfigura().once('value', (snapshot) => {
                store.commit('configuracion', snapshot.val())
            });
            obtenerImpresoras().once("value").then((snapshot) => {
                var array = {}

                if (snapshot.exists) {
                    array = {
                        ip_cocina: snapshot.val().ip_cocina,
                        guardadocumento: snapshot.val().guardadocumento,
                        impresoracocina: snapshot.val().impresoracocina,
                        pdfInMM: snapshot.val().tamano,
                        lMargin: snapshot.val().mizquierdo,
                        rMargin: snapshot.val().mderecho,
                        msuperior: snapshot.val().msuperior,
                        minferior: snapshot.val().minferior,
                        cabecera: snapshot.val().cabecera,
                        piepagina: snapshot.val().piepagina,
                        minferiorgeneral: snapshot.val().minferiorgeneral,
                        telefono: snapshot.val().telefono,
                        refcliente: snapshot.val().refcliente,
                        telfcliente: snapshot.val().telfcliente,
                        placa_cliente: snapshot.val().placa_cliente
                    }
                } else {
                    array = {
                        ip_cocina: '192.168.1.5',
                        guardadocumento: false,
                        impresoracocina: false,
                        pdfInMM: '58',
                        lMargin: '5',
                        rMargin: '3',
                        cabecera: '',
                        minferiorgeneral: '10',
                        piepagina: 'Gracias por su Compra!',
                        telefono: '',
                        refcliente: false,
                        telfcliente: false,
                        placa_cliente: false
                    }
                }
                store.commit("configImpresora", array)
            })

            obtenerSeries().once("value").then((snapshot) => {
                var array = {}
                if (snapshot.exists()) {
                    array = {
                        ticket: snapshot.val().ticket,
                        factura: snapshot.val().factura,
                        boleta: snapshot.val().boleta,
                        notacredito: snapshot.val().notacredito,
                        notaventa: snapshot.val().notaventa,
                        guia: snapshot.val().guia
                    }
                    store.commit("seriesdocumentos", array)
                }
            })
            consultaArchivo().once("value").then((snapshot) => {
                if (snapshot.val() != null && snapshot.val().logoEmpresa != '') {
                    imageToBase64(snapshot.val().logoEmpresa) // Image URL
                        .then(
                            (response) => {
                                //console.log(snapshot.val().logoEmpresa)
                                store.commit("logoempresa", response)
                            })
                        .catch(
                            (error) => {
                                console.log(error)
                            }
                        )
                    imageToBase64('/marca_agua.png') // Image URL
                        .then(
                            (response) => {
                                //console.log(response)
                                store.commit("logoempresa_agua", response)
                            })
                        .catch(
                            (error) => {
                                console.log(error)
                            }
                        )
                }
            })
        },
        obtenerDatausuario(user) {
            //this.dialog=true
            allUsuarios().orderByChild('correo').equalTo(user).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    if (snapshot.exists()) {
                        this.bd = item.val().bd
                        this.ver_bloqueo(item.val().token)
                        store.commit('permisos', item.val())
                        console.log(store.state.permisos)
                        this.obtenerCliente()
                    }
                })

            })
        },
        ver_bloqueo(id) {
            buscarUsuarios_bloqueo(id).on('value', (snapshot) => {
                //console.log(snapshot.val())
                if (snapshot.val()) {
                    store.commit('dialogoBloqueo')
                }
            })
        },
        notificaciones(items) {
            allnotificaciones(items.bd).on('value', (snapshot) => {
                if (snapshot.exists()) {
                    var data = snapshot.val()
                    store.commit('arraynotifica', data)
                    if (data.dialogoactualiza) {
                        store.commit('dialogomensaje')
                    }
                    if (data.dialogoAlerta) {
                        store.commit('dialogoAlerta')
                    }
                }
            })

        },
        obtenproductos() {
            allProductos().on('value', (snapshot) => {
                let array = [];
                snapshot.forEach((item) => {
                    let data = item.val();
                    if (data.c_icono == '') {
                        data.c_icono = '#FFFFFF'
                    }
                    if (data.c_palabra == '') {
                        data.c_palabra = '#000000'
                    }
                    // if (data.activo) {
                    array.push(data);
                    // }

                });
                array.sort(function (a, b) {
                    if (parseFloat(a.orden) > parseFloat(b.orden)) {
                        return 1;
                    }
                    if (parseFloat(a.orden) < parseFloat(b.orden)) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                //console.log(array)
                store.commit('productos', array)
            })
            //    console.log(store.state.permisos.tienda)
            allMonturas().orderByChild('ubicacion').equalTo(store.state.permisos.tienda.trim()).on('value', (snapshot) => {
                let array = [];
                snapshot.forEach((item) => {
                    let data = item.val();
                    if (data.id != undefined && data.marca != undefined && data.modelo != undefined) {
                        array.push(data);
                    }

                });
                //     console.log(array)
                store.commit('monturas', array)
            })
            all_lentes_c().on('value', (snapshot) => {
                let array = [];
                snapshot.forEach((item) => {
                    let data = item.val();
                    array.push(data);
                });
                store.commit('all_lentes_c', array)
            })
            //this.carga_clientes()
            // this.carga_productos()
            // this.carga_monturas()
            if (store.state.permisos.master || store.state.permisos.optometria) {
                allClientes().on('value', (snapshot) => {
                    let array = [];
                    snapshot.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            array.push(data);
                        }
                    });
                    store.commit('clientes', array)
                })
            } else {
                allClientes().orderByChild('tienda').equalTo(store.state.permisos.tienda.trim()).on('value', (snapshot) => {
                    let array = [];
                    snapshot.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            array.push(data);
                        }
                    });
                    store.commit('clientes', array)
                })
            }

            all_tienda().once('value', (snapshot) => {
                let array = [];
                snapshot.forEach((item) => {
                    let data = item.val();
                    array.push(data);
                });
                console.log(array)
                store.commit('array_tiendas', array)
            })
        },
        async sincroniza() {
            if (confirm('seguro de querer sincronizar?')) {
                store.commit("dialogoprogress", 1)

                var snap = await allMonturas().orderByChild('ubicacion').equalTo(store.state.permisos.tienda.trim()).once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '03',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            guardar_local('monturas', data)
                        }
                    });

                }
                store.commit("dialogoprogress", 1)
            }
        },
        async carga_monturas() {
            var res = await leer_local_montura('monturas', store.state.permisos.tienda.trim())
            if (res != '') {
                var ult_consulta = await leer_local('actualizacion')
                var snap = await allMonturas().orderByChild('modificado').startAt(ult_consulta[2].tabla_clientes).once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '03',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            guardar_local('monturas', data)
                        }
                    });
                    this.carga_monturas()
                } else {
                    console.log("cargado normal M")
                    store.commit('monturas', res)
                    guardar_local('actualizacion', {
                        id: '03',
                        tabla_clientes: moment().unix()
                    })
                }
            } else {
                var snap = await allMonturas().orderByChild('ubicacion').equalTo(store.state.permisos.tienda.trim()).once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '03',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            guardar_local('monturas', data)
                        }
                    });
                    this.carga_monturas()
                }
            }
            allMonturas().on('child_changed', async (snapshot) => {
                console.log(snapshot.val())
                guardar_local('monturas', snapshot.val())
                var res = await leer_local_montura('monturas', store.state.permisos.tienda.trim())
                store.commit('monturas', res)

            })
        },
        async carga_clientes() {
            var res = await leer_local('clientes')
            if (res != '') {
                var ult_consulta = await leer_local('actualizacion')
                var snap = await allClientes().orderByChild('modificado').startAt(ult_consulta[0].tabla_clientes).once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '01',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            guardar_local('clientes', data)
                        }
                    });
                    this.carga_clientes()
                } else {
                    console.log("cargado normal")
                    store.commit('clientes', res)
                    guardar_local('actualizacion', {
                        id: '01',
                        tabla_clientes: moment().unix()
                    })
                }
            } else {
                var snap = await allClientes().once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '01',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.id != undefined) {
                            guardar_local('clientes', data)
                        }
                    });
                    this.carga_clientes()
                }
            }
            allClientes().on('child_changed', async (snapshot) => {
                guardar_local('clientes', snapshot.val())
                var res = await leer_local('clientes')
                store.commit('clientes', res)
            })
        },
        async carga_productos() {
            var res = await leer_local('productos')
            if (res != '') {
                var ult_consulta = await leer_local('actualizacion')
                var snap = await allProductos().orderByChild('modificado').startAt(ult_consulta[1].tabla_clientes).once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '02',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.c_icono == '') {
                            data.c_icono = '#FFFFFF'
                        }
                        if (data.c_palabra == '') {
                            data.c_palabra = '#000000'
                        }
                        if (data.activo) {
                            guardar_local('productos', data)
                        }
                    });
                    this.carga_productos()
                } else {
                    console.log("cargado normal p")
                    res.sort(function (a, b) {
                        if (parseFloat(a.orden) > parseFloat(b.orden)) {
                            return 1;
                        }
                        if (parseFloat(a.orden) < parseFloat(b.orden)) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    store.commit('productos', res)
                    guardar_local('actualizacion', {
                        id: '02',
                        tabla_clientes: moment().unix()
                    })
                }
            } else {
                var snap = await allProductos().once('value')
                if (snap.exists()) {
                    guardar_local('actualizacion', {
                        id: '02',
                        tabla_clientes: moment().unix()
                    })
                    snap.forEach((item) => {
                        let data = item.val();
                        if (data.c_icono == '') {
                            data.c_icono = '#FFFFFF'
                        }
                        if (data.c_palabra == '') {
                            data.c_palabra = '#000000'
                        }
                        if (data.activo) {
                            guardar_local('productos', data)
                        }
                    });
                    this.carga_productos()
                }
            }
            allProductos().on('child_changed', async (snapshot) => {
                guardar_local('productos', snapshot.val())
                var res = await leer_local('productos')
                store.commit('productos', res)
            })
        },
        carga_empleados() {
            var array = []
            var tabla = []
            busca_tabla('vendedores').once("value")
                .then((snapshot) => {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        tabla.push(data)
                        array[array.length] = data.nombre
                    });
                    //console.log(tabla)
                    store.commit('tabla_empleados', tabla)
                    store.commit('array_empleados', array)
                })
        }
    },
    beforeCreate() {

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user = user.email
                this.nombreusuario = user.email.slice(0, -13)
                this.barra = false
                //this.obtenEmpresa()  //  habilitar solo para crear usuarios

                this.obtenerDatausuario(user.email)
            } else {
                this.user = null
                store.commit('BD', '')

            }
        })
    },

}
</script>
